import MenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import ButtonCustom from '../../../common/components/ui/button/button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { updatePendingSupplier } from '../../../store/actions/admin';
import * as actions from '../../../store/actions/index';

const UserAccountDetails = (props) => {
    const urlId = useParams();
    const userData = props.location.state.rowData;
    const userInfo = useSelector((state) => state.auth.userInfo);
    const supplierList = useSelector((state) => state.suppliers.supplierList);
    const [supplierData, setSupplierData] = useState({
        userName: userData.fullName,
        userId: urlId.id,
        userEmail: userData.email,
        userCompany: userData.company,
        userTitle: userData.title,
        supplierList: supplierList,
        accessProductCatalog: userData.accessProductCatalog,
        accessSupplierAnalytics: userData.accessSupplierAnalytics,
        accessScoreCard: userData.accessScoreCard,
        accessSalesOut: userData.accessSalesOut,
        accessInventory: userData.AccessInventory,
        accessTerritoryManagement: userData.AccessTerritoryManagement,
        accessPurchaseOrderManagement: userData.accessPurchaseOrderManagement,
        supplierId: '',
        vendorId: '',
        supplierName: '',
        status: ''
    });

    const updateSupplier = async (type) => {
        const updatedDetails = {
            Id: Number(supplierData.userId),
            FullName: supplierData.userName,
            Email: supplierData.userEmail,
            SupplierId: type === 'APPROVED' ? supplierData.supplierId : 0,
            VendorId: type === 'APPROVED' ? supplierData.vendorId : 0,
            SupplierName: type === 'APPROVED' ? supplierData.supplierName : '',
            AccessProductCatalog: type === 'APPROVED' ? supplierData.accessProductCatalog : false,
            AccessSupplierAnalytics: type === 'APPROVED' ? supplierData.accessSupplierAnalytics : false,
            AccessScoreCard: type === 'APPROVED' ? supplierData.accessScoreCard : false,
            AccessSalesOut: type === 'APPROVED' ? supplierData.accessSalesOut : false,
            AccessInventory: type === 'APPROVED' ? supplierData.accessInventory : false,
            AccessTerritoryManagement: type === 'APPROVED' ? supplierData.accessTerritoryManagement : false,
            AccessPurchaseOrderManagement: type === 'APPROVED' ? supplierData.accessPurchaseOrderManagement : false,
            CurrentLoggedInUserName: userInfo?.userName,
            Status: type
        }
        try {
            props.updatePendingSupplierStart();
            const result = await updatePendingSupplier(updatedDetails);
            props.updatePendingSupplierSuccess();
            props.showMessage('Request completed', 'success');
            cancel();
        }
        catch (err) {
            const errMsg = 'Something went wrong';
            props.updatePendingSupplierFail(err?.response?.data?.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        }
    }

    const changeHandlerAccount = id => (event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }
        setSupplierData({
            ...supplierData,
            [id]: value,
        })
    }

    const changeHandlerSupplier = id => (event) => {
        let supplerNameChange = id === 'supplierId' ? event.currentTarget.innerText : supplierData.supplierName;
        let value = event.target.value;
        let selectedVendorId = supplierList.find(supplier => supplier.id === value).vendorId;
        setSupplierData({
            ...supplierData,
            [id]: value,
            vendorId: selectedVendorId,
            supplierName: supplerNameChange
        })

    }

    const cancel = () => {
        props.history.goBack();
    }

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Approve User Account</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <ValidatorForm className='product-details-form' onSubmit={() => { }} noValidate autoComplete="off" >
                        <Row>
                            <Col>
                                <TextValidator disabled id="userName"
                                    label="Name" variant="outlined" value={supplierData.userName || ''} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <TextValidator disabled id="userEmail"
                                    label="Email" variant="outlined" value={supplierData.userEmail || ''} />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={6} lg={6}>
                                <TextValidator disabled id="userCompany"
                                    label="Company" variant="outlined" value={supplierData.userCompany || ''} />
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                                <TextValidator disabled id="userTitle"
                                    label="Title" variant="outlined" value={supplierData.userTitle || ''} />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={6} lg={6}>
                                <TextValidator id="supplierId" select autoComplete="off"
                                    label="Supplier" variant="outlined"
                                    value={supplierData.supplierId || ''} onChange={changeHandlerSupplier('supplierId')}>
                                    {supplierList.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextValidator>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                                <TextValidator id="supplierId" autoComplete="off"
                                    label="Supplier id" variant="outlined" disabled
                                    value={supplierData.supplierId || ''} />
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                                <TextValidator id="vendorId" autoComplete="off" label="Vendor id" variant="outlined" disabled value={supplierData.vendorId || '0'} />
                            </Col>
                        </Row>

                        <Row> 
                            <Col>
                                <FormControlLabel
                                    label="Product Catalog Access" id="accessProductCatalog" variant="outlined" control={<Checkbox checked={supplierData.accessProductCatalog} onChange={changeHandlerAccount('accessProductCatalog')} />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    label="Insight Engine Report Access" id="accessSupplierAnalytics" variant="outlined" control={<Checkbox checked={supplierData.accessSupplierAnalytics} onChange={changeHandlerAccount('accessSupplierAnalytics')} />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    label="Score Card Report Access" id="accessScoreCard" variant="outlined" control={<Checkbox checked={supplierData.accessScoreCard} onChange={changeHandlerAccount('accessScoreCard')} />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    label="Sales Out Report Access" id="accessSalesOut" variant="outlined" control={<Checkbox checked={supplierData.accessSalesOut} onChange={changeHandlerAccount('accessSalesOut')} />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    label="Inventory Report Access" id="accessInventory" variant="outlined" control={<Checkbox checked={supplierData.accessInventory} onChange={changeHandlerAccount('accessInventory')} />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    label="Territory Management Access" id="accessTerritoryManagement" variant="outlined" control={<Checkbox checked={supplierData.accessTerritoryManagement} onChange={changeHandlerAccount('accessTerritoryManagement')} />}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    label="Purchase Order Management Access" id="accessPurchaseOrderManagement" variant="outlined" control={<Checkbox checked={supplierData.accessPurchaseOrderManagement} onChange={changeHandlerAccount('accessPurchaseOrderManagement')} />}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col className="item-state-btn UA-btn">
                                <ButtonCustom className={`${!supplierData.supplierId ? "button" : "button success"}`} disabled={!supplierData.supplierId} variant="contained" onClick={() => updateSupplier('APPROVED')}>Approve</ButtonCustom>
                                <ButtonCustom className='button danger' variant="contained" onClick={() => updateSupplier('REJECTED')}>Deny</ButtonCustom>
                                <ButtonCustom className='button secondary' variant="contained" onClick={cancel}>Cancel</ButtonCustom>
                            </Col>
                        </Row>
                    </ValidatorForm>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePendingSupplierStart: () => dispatch(actions.updatePendingSupplierStart()),
        updatePendingSupplierSuccess: () => dispatch(actions.updatePendingSupplierSuccess()),
        updatePendingSupplierFail: () => dispatch(actions.updatePendingSupplierFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAccountDetails);

