import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ButtonCustom from '../../../common/components/ui/button/button';
import { useSelector } from "react-redux";
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getUserRole, updateEditAccount } from '../../../store/actions/admin';
import MaterialTable from 'material-table';
import { ADMIN_API } from '../../../common/configs/path.config';
import { http } from '../../../common/services/http.service';
import { formatDate } from '../../../common/utils/utility';

const initialInfo = {
    id: '',
    fullName: '',
    userName: '',
    openID: '',
    userRoleId: '',
    supplierId: '',
    vendorId: '',
    accessProductCatalog: false,
    accessSupplierAnalytics: false,
    accessScoreCard: false,
    accessSalesOut: false,
    accessInventory: false,
    accessTerritoryManagement: false,
    accessPurchaseOrderManagement: false,
    isBlocked: false,
    isActive: false,
    emailId: '',
    phoneNum: '',
    officeNum: '',
    address: '',
    addressTwo: '',
    cityName: '',
    stateName: '',
    zipNum: '',
    status: '',
    termsAndConditions: '',
    password: '',
    confirmPass: '',
    supplierName: '',
    company: '',
    title: ''
};

const UserManagementDetails = (props) => {

    const [editAccount, seteditAccount] = useState(initialInfo);
    const supplierList = useSelector((state) => state.suppliers.supplierList);
    const [userRole, setUserRole] = useState([]);
    const loginUserInfo = useSelector((state) => state.auth.userInfo);
    const userDetail = props.location.state.rowData;
    const [userLoginHistory, setUserLoginHistory] = useState([]);
    const [userLoginHistoryCount, setUserLoginHistoryCount] = useState(0);
    const userLoginHistoryRequestURL = ADMIN_API.GET_USER_LOGIN_HISTORY + userDetail.userName;

    useEffect(() => {
        http.get(userLoginHistoryRequestURL)
            .then((res) => {
                setUserLoginHistory(res.data);
                setUserLoginHistoryCount(res.data.length);
            });
    }, []);

    useEffect(() => {
        if (userDetail) {
            seteditAccount({
                ...editAccount,
                id: userDetail.id,
                fullName: userDetail.fullName,
                userName: userDetail.userName,
                openID: userDetail.openID,
                userRoleId: userDetail.userRoleId,
                supplierId: userDetail.supplierId,
                vendorId: userDetail.vendorId,
                supplierRole: userDetail.supplierRole,
                accessProductCatalog: userDetail.accessProductCatalog,
                accessSupplierAnalytics: userDetail.accessSupplierAnalytics,
                accessScoreCard: userDetail.accessScoreCard,
                accessSalesOut: userDetail.accessSalesOut,
                accessInventory: userDetail.accessInventory,
                accessTerritoryManagement: userDetail.accessTerritoryManagement,
                accessPurchaseOrderManagement: userDetail.accessPurchaseOrderManagement,
                termsAndConditions: userDetail.termsAndConditions ? userDetail.termsAndConditions : false,
                isBlocked: userDetail.isBlocked,
                isActive: userDetail.isActive,
                supplierName: userDetail.supplierName ? userDetail.supplierName : '',
                emailId: userDetail.email ? userDetail.email : '',
                phoneNum: userDetail.phoneNumber ? userDetail.phoneNumber : '',
                officeNum: userDetail.officeNumber ? userDetail.officeNumber : '',
                address: userDetail.addressLine1 ? userDetail.addressLine1 : '',
                addressTwo: userDetail.addressLine2 ? userDetail.addressLine2 : '',
                cityName: userDetail.city ? userDetail.city : '',
                stateName: userDetail.state ? userDetail.state : '',
                zipNum: userDetail.zip ? userDetail.zip : '',
                status: userDetail.status ? userDetail.status : '',
                company: userDetail.company ? userDetail.company : '',
                title: userDetail.title ? userDetail.title : '',
                password: '',
                password: '',
                confirmPass: ''
            });
            getUserRoleList();
        }
    }, [userDetail]);

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== editAccount.password) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isCharacter', (value) => {
            if (!value.replace(/[^A-Za-z]/ig, '')) {
                return false;
            }
            return true;
        });

    }, [editAccount.password, editAccount.confirmPass]);

    const getUserRoleList = () => {
        props.getUserRoleStart();
        getUserRole().then(response => {
            setUserRole(response.data);
            props.getUserRoleSuccess();
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getUserRoleFail(err.response.data.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    };

    const changeHandlerAccountInfo = id => (event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }
        seteditAccount({
            ...editAccount,
            [id]: value,
        })
    }

    const changeHandlerSupplierInfo = id => (event) => {
        let supplerNameChange = id === 'supplierId' ? event.currentTarget.innerText : userDetail.supplierName;
        let value = event.target.value;
        let selectedVendorId = supplierList.find(supplier => supplier.id === value).vendorId;
        seteditAccount({
            ...editAccount,
            [id]: value,
            vendorId: selectedVendorId,
            supplierName: supplerNameChange
        })
    }

    const accountInfoSubmit = async (event) => {
        if (event) event.preventDefault();
        const userInfoObj = {
            Id: editAccount.id,
            UserName: editAccount.userName,
            OpenID: editAccount.userName,
            Password: editAccount.password ? editAccount.password : '',
            FullName: editAccount.fullName,
            UserRoleId: editAccount.userRoleId ? editAccount.userRoleId : '',
            SupplierId: String(editAccount.supplierId ? editAccount.supplierId : ''),
            VendorId: String(editAccount.vendorId ? editAccount.vendorId : '0'),
            SupplierName: editAccount.supplierName ? editAccount.supplierName : '',
            SupplierRole: editAccount.supplierRole ? editAccount.supplierRole : '',
            AccessProductCatalog: editAccount.accessProductCatalog,
            AccessSupplierAnalytics: editAccount.accessSupplierAnalytics,
            AccessScoreCard: editAccount.accessScoreCard,
            AccessSalesOut: editAccount.accessSalesOut,
            AccessInventory: editAccount.accessInventory,
            AccessTerritoryManagement: editAccount.accessTerritoryManagement,
            AccessPurchaseOrderManagement: editAccount.accessPurchaseOrderManagement,
            TermsAndConditions: editAccount.termsAndConditions,
            IsBlocked: editAccount.isBlocked,
            IsActive: editAccount.isActive,
            Email: editAccount.emailId ? editAccount.emailId : '',
            PhoneNumber: editAccount.phoneNum ? editAccount.phoneNum : '',
            OfficeNumber: editAccount.officeNum ? editAccount.officeNum : '',
            AddressLine1: editAccount.address ? editAccount.address : '',
            AddressLine2: editAccount.addressTwo ? editAccount.addressTwo : '',
            City: editAccount.cityName ? editAccount.cityName : '',
            State: editAccount.stateName ? editAccount.stateName : '',
            Zip: editAccount.zipNum ? editAccount.zipNum : '',
            Company: editAccount.company ? editAccount.company : '',
            Title: editAccount.title ? editAccount.title : '',
            CurrentLoggedInUserName: loginUserInfo?.userName,
        }
        try {
            props.updateEditAccountStart();
            const result = await updateEditAccount(userInfoObj);
            props.updateEditAccountSuccess();
            props.showMessage('Details Updated', 'success');
            props.history.goBack();
        }
        catch (err) {
            const errMsg = 'Something went wrong';
            props.updateEditAccountFail(err?.response?.data?.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        }
    }

    const loginHistory = () => {
        const list = userLoginHistory || [];
        return (
            <React.Fragment>
                <h5>Login History</h5>
                <p>Total Logins: {userLoginHistoryCount}</p>
                <MaterialTable
                    columns={[
                        {
                            title: 'Login Date', field: 'loginDate',
                            render: row => <span>{formatDate(row["loginDate"])}</span>
                        }
                    ]}
                    data={list}
                    options={{
                        filtering: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                />
            </React.Fragment>
        );
    }

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Edit Account</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <Row>
                        <Col lg={8}>
                            <ValidatorForm className="user-management-details" noValidate autoComplete="off" onSubmit={accountInfoSubmit}>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <h5>User Info</h5>
                                    </Col>
                                    <Col sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator id="fullName" autoComplete="off"
                                                    label="Full name" variant="outlined"
                                                    value={editAccount.fullName || ''} onChange={changeHandlerAccountInfo('fullName')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled id="userName"
                                                    label="User name" variant="outlined"
                                                    value={editAccount.userName || ''} onChange={changeHandlerAccountInfo('userName')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator id="openID"
                                                    label="OpenID" variant="outlined" disabled
                                                    value={editAccount.openID || ''} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <FormControlLabel
                                                    label="Agreed To Terms And Conditions" id="termsAndConditions" variant="outlined" control={<Checkbox checked={editAccount.termsAndConditions} disabled />}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <h5>Supplier</h5>
                                    </Col>
                                    <Col sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator id="supplierId" select autoComplete="off"
                                                    label="Supplier" variant="outlined"
                                                    value={editAccount.supplierId || ''} onChange={changeHandlerSupplierInfo('supplierId')}>
                                                    {supplierList.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextValidator>
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator id="supplierId" autoComplete="off"
                                                    label="Supplier id" variant="outlined" disabled
                                                    value={editAccount.supplierId || ''} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <TextValidator id="vendorId" autoComplete="off" label="Vendor id" variant="outlined" disabled value={editAccount.vendorId || '0'} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <h5>Access</h5>
                                    </Col>
                                    <Col sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator id="userRoleId" select autoComplete="off"
                                                    label="User role" variant="outlined"
                                                    value={editAccount.userRoleId || ''} onChange={changeHandlerAccountInfo('userRoleId')}>
                                                    {userRole.map((option) => (
                                                        <MenuItem key={option.userRoleDescription} value={option.userRoleId}>
                                                            {option.userRoleDescription}
                                                        </MenuItem>
                                                    ))}
                                                </TextValidator>
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    label="Active" id="isActive" variant="outlined" control={<Checkbox checked={editAccount.isActive} onChange={changeHandlerAccountInfo('isActive')} />}
                                                />
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    label="Blocked" id="isBlocked" variant="outlined" control={<Checkbox checked={editAccount.isBlocked} onChange={changeHandlerAccountInfo('isBlocked')} />}
                                                />
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    label="Product Catalog Access" id="accessProductCatalog" variant="outlined" control={<Checkbox checked={editAccount.accessProductCatalog} onChange={changeHandlerAccountInfo('accessProductCatalog')} />}
                                                />
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    label="Insight Engine Report Access" id="accessSupplierAnalytics" variant="outlined" control={<Checkbox checked={editAccount.accessSupplierAnalytics} onChange={changeHandlerAccountInfo('accessSupplierAnalytics')} />}
                                                />
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    label="Score Card Report Access" id="accessScoreCard" variant="outlined" control={<Checkbox checked={editAccount.accessScoreCard} onChange={changeHandlerAccountInfo('accessScoreCard')} />}
                                                />
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    label="Sales Out Report Access" id="accessSalesOut" variant="outlined" control={<Checkbox checked={editAccount.accessSalesOut} onChange={changeHandlerAccountInfo('accessSalesOut')} />}
                                                />
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    label="Inventory Report Access" id="accessInventory" variant="outlined" control={<Checkbox checked={editAccount.accessInventory} onChange={changeHandlerAccountInfo('accessInventory')} />}
                                                />
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    label="Territory Management Access" id="accessTerritoryManagement" variant="outlined" control={<Checkbox checked={editAccount.accessTerritoryManagement} onChange={changeHandlerAccountInfo('accessTerritoryManagement')} />}
                                                />
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <FormControlLabel
                                                    label="Purchase Order Management Access" id="accessPurchaseOrderManagement" variant="outlined" control={<Checkbox checked={editAccount.accessPurchaseOrderManagement} onChange={changeHandlerAccountInfo('accessPurchaseOrderManagement')} />}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <h5>Contact</h5>
                                    </Col>
                                    <Col sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="emailId" autoComplete="off"
                                                    label="Email" variant="outlined" name="email"
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={['Email is required', 'Email is not valid']}
                                                    value={editAccount.emailId || ''} onChange={changeHandlerAccountInfo('emailId')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="phoneNum" autoComplete="off"
                                                    label="Phone Number" variant="outlined"
                                                    validators={['required', 'isNumber']} errorMessages={['Phone Number is required', 'Number only']}
                                                    value={editAccount.phoneNum || ''} onChange={changeHandlerAccountInfo('phoneNum')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="officeNum" autoComplete="off"
                                                    label="Office Number" variant="outlined"
                                                    validators={['isNumber']} errorMessages={['Office Number is required', 'Number Only']}
                                                    value={editAccount.officeNum || ''} onChange={changeHandlerAccountInfo('officeNum')} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <h5>Address</h5>
                                    </Col>
                                    <Col sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="address" autoComplete="off"
                                                    label="Address Line 1" variant="outlined"
                                                    validators={['required']} errorMessages={['Address Line 1 is required']}
                                                    value={editAccount.address || ''} onChange={changeHandlerAccountInfo('address')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="addressTwo" autoComplete="off"
                                                    label="Address Line 2" variant="outlined"
                                                    value={editAccount.addressTwo || ''} onChange={changeHandlerAccountInfo('addressTwo')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="cityName" autoComplete="off"
                                                    label="City" variant="outlined"
                                                    validators={['required', 'isCharacter']} errorMessages={['City is required', 'Character only']}
                                                    value={editAccount.cityName || ''} onChange={changeHandlerAccountInfo('cityName')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="stateName" autoComplete="off"
                                                    label="State" variant="outlined"
                                                    validators={['required', 'isCharacter']} errorMessages={['State is required', 'Character only']}
                                                    value={editAccount.stateName || ''} onChange={changeHandlerAccountInfo('stateName')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="zipNum" autoComplete="off"
                                                    label="Zip" variant="outlined"
                                                    validators={['required', 'isNumber']} errorMessages={['Zip is required', 'Number only']}
                                                    value={editAccount.zipNum || ''} onChange={changeHandlerAccountInfo('zipNum')} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <h5>Supplier Info</h5>
                                    </Col>
                                    <Col sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="company" autoComplete="off"
                                                    label="Company" variant="outlined"
                                                    value={editAccount.company || ''} onChange={changeHandlerAccountInfo('company')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator disabled={editAccount.status !== 'APPROVED'} id="title" autoComplete="off"
                                                    label="Title" variant="outlined"
                                                    value={editAccount.title || ''} onChange={changeHandlerAccountInfo('title')} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <h5>Password</h5>
                                    </Col>
                                    <Col sm={12} md={12} lg={12}>
                                        <Row>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator id="password" autoComplete="off"
                                                    type="password" label="New Password" variant="outlined"
                                                    value={editAccount.password || ''} onChange={changeHandlerAccountInfo('password')} />
                                            </Col>
                                            <Col sm={12} md={6} lg={6}>
                                                <TextValidator id="confirmPass" autoComplete="off"
                                                    type="password" label="Confirm Password" variant="outlined"
                                                    validators={['isPasswordMatch']} errorMessages={['The entered passwords do not match.']}
                                                    value={editAccount.confirmPass || ''} onChange={changeHandlerAccountInfo('confirmPass')} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="account-info-button-container">
                                        <ButtonCustom className='button primary userManagement' variant="contained" type='submit'>
                                            Update
                                        </ButtonCustom>
                                    </Col>
                                </Row>
                            </ValidatorForm>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8}>
                            {loginHistory()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserRoleStart: () => dispatch(actions.getUserRoleStart()),
        getUserRoleSuccess: () => dispatch(actions.getUserRoleSuccess()),
        getUserRoleFail: () => dispatch(actions.getUserRoleFail()),
        updateEditAccountStart: () => dispatch(actions.updateEditAccountStart()),
        updateEditAccountSuccess: () => dispatch(actions.updateEditAccountSuccess()),
        updateEditAccountFail: () => dispatch(actions.updateEditAccountFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserManagementDetails);
