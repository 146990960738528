import * as actions from './actions';
import { SUPPLIER_API } from '../../common/configs/path.config';
import { http } from '../../common/services/http.service';
import { showMessage } from './message-notification';

export const getDocumentsStart = () => {
	return {
		type: actions.GET_DOCUMENTS_START,
	};
};

export const getDocumentsSuccess = (documents) => {
	return {
		type: actions.GET_DOCUMENTS_SUCCESS,
		documents: documents,
	};
};

export const getDocumentsFail = error => {
	return {
		type: actions.GET_DOCUMENTS_FAIL,
		error: error,
	};
};

export const getDocuments = () => {
	const url = SUPPLIER_API.GET_DOCUMENTS;
	return http.get(url);
};