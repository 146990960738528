import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../store/actions/index';
import { getProductRequests } from '../../store/actions/products';
import { getDocuments } from '../../store/actions/documents';
import MaterialTable from 'material-table';
import { STATUS_PENDING } from '../../common/constants';
import { formatDate } from './../../common/utils/utility';

const ProductsSummary = (props) => {
    const currentURL = window.location.href;
    let netsuiteDomain = 'https://3395596.app.netsuite.com';
    if (currentURL.includes('dev')) {
        netsuiteDomain = 'https://3395596-sb1.app.netsuite.com';
    }

    const userInfo = useSelector((state) => state.auth.userInfo);
    const [documentList, setDocumentList] = useState([]);
    const [productRequests, setProductRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDocumentsLoading, setIsDocumentsLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [pageValue, setPageValue] = useState({
        offset: 0,
        perPage: 6,
        currentPage: 0,
        pageCount: 0
    });

    const getRequestsList = () => {
        props.getProductRequestsStart();
        getProductRequests(userInfo.supplierId).then(response => {
            setIsLoading(false);
            setProductRequests(response.data);
            props.getProductRequestsSuccess(response.data);
            setPageCount(Math.ceil(response.data?.length / pageValue.perPage));
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.getProductRequestsFail(err?.response?.data?.error);
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    };

    const getDocumentsList = () => {
        getDocuments().then(response => {
            setIsDocumentsLoading(false);

            let docs = response.data?.items;

            docs.forEach((item, index) => {
                item.url = netsuiteDomain + item.url;

                if (item.description == null) {
                    item.description = "";
                }
            }, docs);

            setDocumentList(docs);
        }).catch((err) => {
            const errMsg = 'Something went wrong';
            props.showMessage(err?.response?.data?.error || errMsg);
        });
    };

    useEffect(() => {
        if (userInfo) {
            getRequestsList();
            getDocumentsList();
        }
    }, [userInfo]);

    //const notifications = useSelector((state) => state.products.notifications);
    /*const renderNotifications = () => {
        const list = notifications?.slice() || [];
        return list.map((item, ind) => {
            return (
                <React.Fragment key={item.productRequestId}>
                    <Row>
                        <Col xs={7}>{item.storeDisplayName} update request {item.status}</Col>
                        <Col>{item.modifiedBy}</Col>
                        <Col>{formatDate(item.modifiedTimestamp)}</Col>
                    </Row>
                </React.Fragment>
            );
        });
    }*/
    /*return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Notiﬁcations</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    <Row className="product-summary">
                        <Col xs={7} >Subject Line </Col>
                        <Col >From </Col>
                        <Col >Date/Time(UTC) </Col>
                    </Row>
                    {renderNotifications()}
                </Col>
            </Row>
        </Container>
    )*/

    const renderProductRequestRows = () => {
        productRequests.map((item, index) => productRequests.unshift(productRequests.splice(index, 1)[0]));
        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Subject Line', field: 'storeDisplayName' },
                        { title: 'Status', field: 'status' },
                        { title: 'From', field: 'createdBy' },
                        {
                            title: 'Updated Date (UTC)', field: 'modifiedTimestamp',
                            render: row => <span>{formatDate(row["modifiedTimestamp"])}</span>
                        },
                    ]}
                    data={productRequests}
                    onRowClick={(event, rowData) => { props.history.push('/home/itemRequest/' + rowData.productRequestId, rowData) }}
                    options={{
                        filtering: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                    isLoading={isLoading}
                    localization={{ body: { emptyDataSourceMessage: "" } }}
                />

            </React.Fragment>
        );
    }

    const renderDocumentRows = () => {
        /*documentList.items.map((item, index) => documentList.unshift(documentList.splice(index, 1)[0]));*/
        return (
            <React.Fragment>
                <MaterialTable
                    columns={[
                        { title: 'Document', field: 'description' }
                    ]}
                    data={documentList}
                    onRowClick={(event, rowData) => { window.open(rowData.url, '_blank', 'noopener,noreferrer') }}
                    options={{
                        filtering: false,
                        actionsColumnIndex: -1,
                        search: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        headerStyle: {
                            backgroundColor: 'gray',
                            color: '#FFF'
                        },
                    }}
                    isLoading={isDocumentsLoading}
                    localization={{ body: { emptyDataSourceMessage: "" } }}
                />

            </React.Fragment>
        );
    }

    return (
        <Container>
            <Row className="page-header-container">
                <Col>
                    <h2 className="page-header">Notiﬁcations</h2>
                </Col>
            </Row>
            <Row className="page-content-container">
                <Col className="page-content-container-inner">
                    {renderProductRequestRows()}


                    <div className="page-header-container row">
                        <div className="col">
                            <h2 className="page-header">Documents</h2>
                        </div>
                    </div>
                    {renderDocumentRows()}
                </Col>
            </Row>
        </Container>
    )
};


const mapStateToProps = state => {
    return {
        userInfo: state.auth.userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProductRequests: (supplierId) => dispatch(actions.getProductRequests(supplierId)),
        getProductRequestsStart: () => dispatch(actions.getProductRequestsStart()),
        getProductRequestsSuccess: (data) => dispatch(actions.getProductRequestsSuccess(data)),
        getProductRequestsFail: () => dispatch(actions.getProductRequestsFail()),
        showMessage: (msg, type) => dispatch(actions.showMessage(msg, type))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSummary);